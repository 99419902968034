export const footerLinks = (app) => {
  return [
    {
      title: 'Video '+app.$t('converter'),
      links: [
        { text: 'MP4 '+app.$t('converter'), link: '/mp4-converter' },
        { text: 'Video ' + app.$t('to') + ' GIF', link: '/convert/video-to-gif' },
        { text: 'MOV ' + app.$t('to') + ' MP4', link: '/mov-to-mp4' },
        { text: app.$t('converter_video_converter'), link: '/video-converter' },
      ],
      more: '/video-converter'
    },
    {
      title: 'Audio '+app.$t('converter'),
      links: [
        { text: 'MP3 ' + app.$t('converter'), link: '/mp3-converter' },
        { text: 'MP4 ' + app.$t('to') + ' MP3', link: '/mp4-to-mp3' },
        { text: 'Video ' + app.$t('to') + ' MP3', link: '/convert/video-to-mp3' },
        { text: app.$t('converter_audio_converter'), link: '/audio-converter' },
      ],
      more: '/audio-converter'
    },
    {
      title: 'Image '+app.$t('converter'),
      links: [
        { text: 'JPG ' + app.$t('to') + ' PDF', link: '/jpg-to-pdf' },
        { text: 'PDF ' + app.$t('to') + ' JPG', link: '/pdf-to-jpg' },
        { text: 'HEIC ' + app.$t('to') + ' JPG', link: '/heic-to-jpg' },
        { text: 'Image ' + app.$t('to') + ' PDF', link: '/convert/image-to-pdf' },
        { text: app.$t('converter_image_converter'), link: '/image-converter' },
      ],
      more: '/image-converter'
    },
    {
      title: app.$t('footer_link_title_document_and_ebook'),
      links: [
        { text: 'PDF ' + app.$t('to') + ' Word', link: '/pdf-to-word' },
        { text: 'EPUB ' + app.$t('to') + ' PDF', link: '/epub-to-pdf' },
        { text: 'EPUB ' + app.$t('to') + ' Mobi', link: '/epub-to-mobi' },
        { text: app.$t('converter_document_converter'), link: '/document-converter' },
      ],
      more: '/document-converter'
    },
    {
      title: app.$t('footer_link_title_archive_and_time'),
      links: [
        { text: 'RAR ' + app.$t('to') + ' ZIP', link: '/rar-to-zip' },
        { text: 'PST ' + app.$t('to') + ' EST', link: '/time/pst-to-est' },
        { text: 'CST ' + app.$t('to') + ' EST', link: '/time/cst-to-est' },
        { text: app.$t('converter_archive_converter'), link: '/archive-converter' },
      ],
      more: '/archive-converter'
    },
    {
      title: app.$t('converter_unit_converter'),
      links: [
        { text: 'Pound ' + app.$t('to') + ' KG', link: '/unit/lbs-to-kg' },
        { text: 'KG ' + app.$t('to') + ' Pound', link: '/unit/kg-to-lbs' },
        { text: 'Feet ' + app.$t('to') + ' Meter', link: '/unit/feet-to-meters' },
        { text: app.$t('converter_unit_converter'), link: '/unit-converter' },
      ],
      more: '/unit-converter'
    },
    {
      title: app.$t('web_apps'),
      links: [
        { text: app.$t('collage_maker'), link: 'https://www.photojoiner.com/features/collage-maker', type: 'url' },
        { text: app.$t('image_resizer'), link: 'https://imageresizer.com/', type: 'url' },
        { text: app.$t('crop_image'), link: 'https://imageresizer.com/crop-image', type: 'url' },
        { text: app.$t('color_picker'), link: 'https://imageresizer.com/color-picker', type: 'url' },
      ]
    },
    {
      title: app.$t('mobile_apps'),
      links: [
        { text: app.$t('collage_maker') + ' Android', link: 'https://play.google.com/store/apps/details?id=com.freeconvert.collagemaker', type: 'url' },
        { text: app.$t('collage_maker') + ' iOS', link: 'https://apps.apple.com/us/app/collage-maker-photojoiner/id1587802950', type: 'url' },
        { text: app.$t('converter_image_converter') + ' Android', link: 'https://play.google.com/store/apps/details?id=com.freeconvert.image_converter', type: 'url' },
        { text: app.$t('converter_image_converter') + ' iOS', link: 'https://apps.apple.com/us/app/image-converter-pixconverter/id1570076731', type: 'url' },
      ]
    },
  ]
}
export const footerNavigationLinks = (app) => {
  return [
      { text: app.$t('about_us'), link: '/about' },
      { text: app.$t('donate'), link: '/donate' },
      { text: app.$t('privacy'), link: '/privacy' },
      { text: app.$t('terms_of_service'), link: '/terms' }
    ]
}
export const footerSocialLinks = () => {
  return [
      { iconName: 'facebook', link: 'https://www.facebook.com/freeconvert' },
      { iconName: 'twitter', link: 'https://www.twitter.com/freeconvert' },
      { iconName: 'instagram', link: 'https://www.facebook.com/freeconvert' },
      { iconName: 'linkedin', link: 'https://www.twitter.com/freeconvert' }
    ]
}

